@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

